<template>
    <div class="w-100">
        <select v-model="valueItem" class="form-control">
            <option :value="null"></option>
            <option v-for="value in values" :key="value[trackBy]" :value="value[trackBy]">
                {{ value[valueLabel] }}
            </option>
        </select>
    </div>
</template>
<script>
import {abstractField} from "vue-form-generator"
import FormMixin from "vue-titan-form/src/form/FormMixin";

export default {
    name: "NativeSelect",
    mixins: [
        abstractField,
        FormMixin
    ],
    computed: {
        trackBy() {
            return this.schema.trackBy === undefined ? 'id' : this.schema.trackBy
        },
        valueLabel() {
            return this.schema.valueLabel === undefined ? 'name' : this.schema.valueLabel
        },
        required() {
            return this.schema.required === undefined ? false : this.schema.required
        },
        values: {
            cache: false,
            get() {
                if (this.schema.codeBook !== undefined && this.schema.codeBook !== null) {
                    return this.$store.getters['codeBook/get'](this.schema.codeBook)
                } else if (this.schema.values === undefined) {
                    return [];
                } else if (typeof this.schema.values === 'function') {
                    return this.schema.values();
                }
                return this.schema.values
            }
        },
        isConstant() {
            return this.schema.constant === undefined ? false : this.schema.constant
        },
    },
    watch: {
        valueItem() {
            if (this.valueItem === null || this.valueItem === '') {
                this.value = null
                return;
            }
            if (this.isConstant === true) {
                this.value = this.valueItem
                return;
            }

            const item = this.values.find((item) => this.valueItem === item[this.trackBy])
            this.value = item === undefined ? null : item
        },
        value() {
            this.setDefault()
        }
    },
    mounted() {
        this.setDefault()
    },
    methods: {
        setDefault() {
            if (this.value === null || this.value === '') {
                this.valueItem = null
                return;
            }
            this.valueItem = this.isConstant === true ? this.value : this.value[this.trackBy]
        }
    },
    data() {
        return {
            valueItem: null,
        }
    }
}
</script>

<style scoped>

</style>