import Vue from "vue";
import Autocomplete from "@/crm/Autocomplete";
import NativeDate from "@/crm/NativeDate";
import NativeSelect from "@/crm/NativeSelect";
import NativeTime from "@/crm/NativeTime";
import PatientAutocomplete from "@/components/form-fields/PatientAutocomplete.vue";
import FieldColor from "@/components/form-fields/FieldColor.vue";
import Rating from "@/crm/Rating.vue";

Vue.component("field-autocomplete", Autocomplete);
Vue.component("field-nativeDate", NativeDate);
Vue.component("field-nativeSelect", NativeSelect);
Vue.component("field-nativeTime", NativeTime);
Vue.component("field-patientAutocomplete", PatientAutocomplete);
Vue.component("field-color", FieldColor);
Vue.component("field-rating", Rating);