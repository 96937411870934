import {
    faCheck,
    faFileInvoice,
    faHome,
    faReceipt,
    faCar,
    faAngleDown,
    faAngleUp,
    faSearch,
    faSignOutAlt,
    faBuilding,
    faUser,
    faFrown,
    faFileAlt,
} from '@fortawesome/free-solid-svg-icons'

import Vue from "vue";
import {library} from '@fortawesome/fontawesome-svg-core'
import {elementsIcons} from "vue-titan-elements";
import {formElementsIcons} from "vue-titan-form";
import {translatorIcons} from "vue-titan-translator";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon);

const faIcons = [...elementsIcons, ...formElementsIcons, ...translatorIcons]
faIcons.forEach((icon) => {
    library.add(icon)
});
library.add(
    faCheck,
    faFileInvoice,
    faHome,
    faReceipt,
    faCar,
    faAngleDown,
    faAngleUp,
    faSearch,
    faSignOutAlt,
    faBuilding,
    faUser,
    faFrown,
    faFileAlt,
);
