<template>
    <div class="field-color">
        <color v-for="color in colors"
               :color="color.id"
               :key="color.id"
               :selected="color.id === value"
               @select="select(color.id)"
        />
    </div>
</template>
<script>
import {abstractField} from "vue-form-generator"
import FormMixin from "vue-titan-form/src/form/FormMixin";
import {colors} from "@/store/codeBook";
import Color from "@/components/Color.vue";

export default {
    name: 'PatientAutocomplete',
    components: {
        Color,
    },
    mixins: [abstractField, FormMixin],
    computed: {
        colors() {
            return colors(this.t)
        },
        placeholder() {
            return this.schema.placeholder === undefined ? '' : this.schema.placeholder;
        },
    },
    methods: {
        select(value) {
            this.value = value
        }
    },
};
</script>
<style lang="scss" scoped>
.field-color {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > * {
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
    }
}
</style>