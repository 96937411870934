import api, {apiLink, defaultPaginator, query} from '../api';

const baseUrl = 'invoice'

export default {
    namespaced: true,
    state: {
        listFilter: {
            group: null,
            search: '',
            year: null,
        },
        invoices: {
            loading: false,
            paginator: {...defaultPaginator},
            items: [],
        },
        invoice: {
            loading: false,
            item: null
        },
    },
    getters: {},
    actions: {
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Invoice was created')
        },
        copy(store, data) {
            return api.editItem(store, baseUrl + '/copy/' + data.id, data, 'Invoice was copied')
        },
        update(store) {
            store.state.invoice.loading = false

            const data = store.state.invoice.item
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Invoice was updated', (response) => {
                store.commit('setInvoice', response)
            })
        },
        markAsPaid(store, data) {
            return api.editItem(store, baseUrl + '/paid/' + data.id, data, 'Invoice was paid')
        },
        removeById(store, id) {
            return api.deleteItem(store, baseUrl + '/' + id, 'Invoice was deleted')
        },
        getById(store, id) {
            store.state.invoice.loading = true

            return api.getItem(store, baseUrl + '/' + id, (response) => {
                store.commit('setInvoice', response)
            })
        },
        list(store, params) {
            store.state.invoices.loading = true

            return api.list(store, baseUrl + '/list', params, ({data}) => {
                store.state.invoices = {
                    loading: false,
                    paginator: data.paginator,
                    items: data.items,
                }
            })
        },
        items(store, params) {
            return api.getItem(store, baseUrl + '/items/1/20' + query(params))
        },
    },
    mutations: {
        setInvoice(state, response) {
            state.invoice.loading = false
            state.invoice.item = response.data.item
        }
    }
}

export const invoiceLink = (id, renderSignature = true) => {
    return apiLink() + baseUrl + '/download/' + id + (renderSignature === true ? '' : '?renderSignature=0')
}