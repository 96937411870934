import api, {query} from '../api';

const baseUrl = 'report'

export default {
    namespaced: true,
    state: {
        year: null,
        groupId: null,
    },
    actions: {
        economy(store) {
            return api.getItem(store, baseUrl + '/economy' + query({year: store.state.year}))
        },
        clients(store, data) {
            const params = {
                year: store.state.year,
                groupId: store.state.groupId,
            }
            return api.getItem(store, baseUrl + '/client/' + data.page + '/' + data.perPage + query(params))
        },
        incomeLastMonths(store, params) {
            return api.getItem(store, baseUrl + '/invoice-last-months' + query(params))
        },
        costLastMonths(store, params) {
            return api.getItem(store, baseUrl + '/cost-last-months' + query(params))
        },
        taxLimit(store) {
            return api.getItem(store, baseUrl + '/tax-limit')
        },
        costByType(store, params = {}) {
            return api.getItem(store, baseUrl + '/cost-by-type' + query(params))
        },
        tax(store, year) {
            return api.getItem(store, baseUrl + '/tax/' + year)
        }
    },
    mutations: {},
    getters: {},
}

export const supportedColors = [
    '#6f42c1',
    '#2564c3',
    '#33b333',
    '#f4b404',
    '#FE8B02',
    '#b22032'
]

export const selectSupportedColor = (index) => {
    const supportedColorsCount = supportedColors.length
    const colorIndex = index < supportedColorsCount ? index : index % supportedColorsCount;
    return supportedColors[colorIndex]
}

export const reports = (t, account) => {
    return [
        {
            name: t('WaitingTimeReport'),
            route: {
                name: 'waitingTimeReport',
            }
        }, {
            name: t('TimeSheetReport'),
            route: {
                name: 'timeSheetReport',
            }
        }, {
            name: t('ServicesReport'),
            route: {
                name: 'servicesReport',
            }
        }, {
            name: t('ServiceDuration'),
            route: {
                name: 'serviceDurationReport',
            }
        }, {
            name: t('Reviews'),
            route: {
                name: 'reviewsReport',
            }
        }, {
            name: t('Export data'),
            route: {
                name: 'exportData',
            }
        },
    ]
}
