import api from './../api';

export default {
    namespaced: true,

    state: {
        item: {}
    },
    getters: {

    },
    actions: {
        list(store, params) {
            return new Promise((resolve, reject) => {
                api.post('acl/list', params).then(response => {
                    store.commit('setItems', response.data);
                    resolve();
                }).catch(error => {
                    reject(error.response);
                });
            });
        },
    },
    mutations: {
        setItems(state, data) {
            state.items = data;
        }
    }
}