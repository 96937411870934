<template>
    <div id="app">
        <titan-alerts :i18n="t"/>
        <router-view v-if="$store.state.translate.loading === false"/>
    </div>
</template>

<script>
import {TitanAlerts} from "vue-titan-elements";

export default {
    name: 'Private',
    components: {
        TitanAlerts
    },
    mounted() {
        this.setRouteTitle()

    },
    watch: {
        '$store.state.translate.loading'() {
            this.setRouteTitle()
        },
        '$route.name'(current, prev) {
            const body = document.getElementsByTagName("body")[0]
            body.classList.remove('route-' + prev)
            body.classList.add('route-' + current)
            this.setRouteTitle()
        }
    },
    methods: {
        setRouteTitle() {
            if (this.$store.state.translate.loading === false && this.$route !== undefined) {
                document.title = this.t(this.$route.name + 'Page') + ' - Špánik'
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/template.scss';
</style>
