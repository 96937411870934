import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import VueChart from 'vue-chart-js'
import TitanElements from "vue-titan-elements";
import Translator from 'vue-titan-translator'
import VueTransmit from "vue-transmit"
import {DropdownPlugin} from 'bootstrap-vue'
import {apiLink} from "@/store/api";
import {VBTooltipPlugin} from 'bootstrap-vue'
import vClickOutside from 'v-click-outside'
import messages from './locales.json'
import './fontawesome'
import './form-fields'
import './filters'
import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";

Vue.use(vClickOutside)
Vue.use(VBTooltipPlugin)
Vue.use(VueChart)
Vue.use(DropdownPlugin)
Vue.use(TitanElements, {store})
Vue.use(VueTransmit)

Vue.config.productionTip = false;

const translatorMessages = () => {
    return process.env.VUE_APP_DB_TRANSLATES === '1' ? {} : messages
}

Translator.install(Vue, {
    store,
    apiUrl: apiLink(),
    flagPath: '/flags/4x3/{lang}.svg',
    languages: {
        ADM: [
            {
                key: 'sk',
                name: 'Slovenčina',
                default: true,
            }, {
                key: 'gb',
                name: 'English',
            }
        ]
    },
    type: 'ADM',
    messages: translatorMessages(),
})

if (process.env.VUE_APP_SENTRY_ENV !== 'development') {
    // Sentry.init({
    //     Vue,
    //     dsn: "https://a2c698595a124651b068188296457bb8@o198312.ingest.sentry.io/4504452099801088",
    //     integrations: [
    //         new BrowserTracing({
    //             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    //             tracePropagationTargets: [
    //                 "http://vistamet.lcl:8080",
    //                 "https://vistamet.spanik.sk",
    //                 /^\//
    //             ],
    //         }),
    //     ],
    //     ignoreErrors: [
    //         // "Non-Error exception captured",
    //         // "Non-Error promise rejection captured"
    //     ],
    //     environment: process.env.VUE_APP_SENTRY_ENV,
    //     tracesSampleRate: 1.0,
    // });
}

export const formatNumber = (number, minimumFractionDigits = 2) => {
    return Number(number).toLocaleString("sk-SK", {
        minimumFractionDigits: minimumFractionDigits,
        maximumFractionDigits: minimumFractionDigits
    });
};

export const formatDate = (date) => {
    const today = new Date(date);

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return dd + '.' + mm + '.' + yyyy;
}

export const formatISODate = (date) => {
    const today = new Date(date);

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return yyyy + '-' + mm + '-' + dd;
}


export const formatDay = (date, t) => {
    const today = new Date(date);
    return t('Day' + today.getDay())
}

Vue.filter('formatDate', formatDate)
Vue.filter('day', formatDay)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');


