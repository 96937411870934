import api, {apiLink, defaultPaginator} from '../api';

const baseUrl = 'cost'

export default {
    namespaced: true,
    state: {
        listFilter: {
            group: null,
            search: '',
            year: null,
        },
        costs: {
            loading: false,
            paginator: {...defaultPaginator},
            items: [],
        },
        cost: {
            loading: false,
            item: null
        },
    },
    getters: {},
    actions: {
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Cost was created')
        },
        copy(store, data) {
            return api.editItem(store, baseUrl + '/copy/' + data.id, data, 'Cost was copied')
        },
        update(store, data) {
            store.state.cost.loading = false

            return api.editItem(store, baseUrl + '/' + data.id, data, 'Cost was updated', (response) => {
                store.commit('setCost', response)
            })
        },
        removeById(store, id) {
            return api.deleteItem(store, baseUrl + '/' + id, 'Cost was deleted')
        },
        getById(store, id) {
            store.state.cost.loading = true

            return api.getItem(store, baseUrl + '/' + id, (response) => {
                store.commit('setCost', response)
            })
        },
        list(store, params) {
            store.state.costs.loading = true

            return api.list(store, baseUrl + '/list', params, ({data}) => {
                store.state.costs = {
                    loading: false,
                    paginator: data.paginator,
                    items: data.items,
                }
            })
        },
    },
    mutations: {
        setCost(state, response) {
            state.cost.loading = false
            state.cost.item = response.data.item
        }
    }
}

export const costFileLink = (id, fileName) => {
    return apiLink() + 'file/cost/' + id + '/' + fileName
}
