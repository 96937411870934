import api, {defaultPaginator} from '../api';

const baseUrl = 'group'

export default {
    namespaced: true,
    state: {
        groups: {
            loading: false,
            paginator: {...defaultPaginator},
            items: [],
        },
        group: {
            loading: false,
            item: null
        },
    },
    getters: {},
    actions: {
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Group was created')
        },
        update(store, data) {
            store.state.group.loading = false
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Group was updated', (response) => {
                store.commit('setGroup', response)
            })
        },
        getById(store, id) {
            store.state.group.loading = true

            return api.getItem(store, baseUrl + '/' + id, (response) => {
                store.commit('setGroup', response)
            })
        },
        list(store, params) {
            store.state.groups.loading = true

            return api.list(store, baseUrl + '/list', params, ({data}) => {
                store.state.groups = {
                    loading: false,
                    paginator: data.paginator,
                    items: data.items,
                }
            })
        },
    },
    mutations: {
        setGroup(state, response) {
            state.group.loading = false
            state.group.item = response.data.item
        }
    }
}
