import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

Vue.use(VueRouter)

export const hasRouteAccess = (user, route) => {
    const roles = route.meta.roles

    if (roles === undefined) {
        throw new Error('Roles are not defined for route');
    }

    // User is not logged in and is in private area
    if (user === null && roles.includes('visitor') === false) {
        return {name: 'login'}
    }

    // User is logged in and is in public area
    if (user !== null && roles.includes('visitor') === true) {
        return {name: 'dashboard'}
    }

    return true;
}

const userRole = ['user']
const visitorRole = ['visitor']


const routes = [
    {
        path: '/public',
        component: () => import('../views/public/Public.vue'),
        meta: {roles: visitorRole},
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('../views/public/Login'),
                meta: {roles: visitorRole},
            }, {
                path: 'forgotten-password',
                name: 'forgottenPassword',
                component: () => import('../views/public/LostPassword'),
                meta: {roles: visitorRole},
            }, {
                path: 'reset-password/:id/:hash',
                name: 'resetPassword',
                component: () => import('../views/public/ResetPassword'),
                meta: {roles: visitorRole},
            }
        ]
    }, {
        path: '/',
        component: () => import('../views/private/Private.vue'),
        meta: {roles: userRole},
        children: [
            {
                path: '/',
                redirect: {name: 'dashboard'},
                meta: {roles: userRole},
            }, {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('../views/private/dashboard/DashboardPage.vue'),
                meta: {roles: userRole},
            }, {
                path: 'taxes',
                name: 'taxes',
                component: () => import('../views/private/tax/TaxPage.vue'),
                meta: {roles: userRole},
            }, {
                path: 'invoice',
                component: () => import('../views/private/invoices/InvoicePage.vue'),
                children: [
                    {
                        path: 'list/:page',
                        name: 'invoiceList',
                        component: () => import('../views/private/invoices/pages/ListPage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'create',
                        name: 'createInvoice',
                        component: () => import('../views/private/invoices/pages/CreatePage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'edit/:id/:tab',
                        name: 'editInvoice',
                        component: () => import('../views/private/invoices/pages/EditPage.vue'),
                        meta: {roles: userRole},
                    },
                ]
            }, {
                path: 'group',
                component: () => import('../views/private/group/GroupPage.vue'),
                children: [
                    {
                        path: 'list/:page',
                        name: 'groupList',
                        component: () => import('../views/private/group/pages/ListPage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'create',
                        name: 'createGroup',
                        component: () => import('../views/private/group/pages/CreatePage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'edit/:id',
                        name: 'editGroup',
                        component: () => import('../views/private/group/pages/EditPage.vue'),
                        meta: {roles: userRole},
                    },
                ]
            }, {
                path: 'client',
                component: () => import('../views/private/client/ClientPage.vue'),
                children: [
                    {
                        path: 'list/:page',
                        name: 'clientList',
                        component: () => import('../views/private/client/pages/ListPage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'create',
                        name: 'createClient',
                        component: () => import('../views/private/client/pages/CreatePage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'edit/:id',
                        name: 'editClient',
                        component: () => import('../views/private/client/pages/EditPage.vue'),
                        meta: {roles: userRole},
                    },
                ]
            }, {
                path: 'cost',
                component: () => import('../views/private/cost/CostPage.vue'),
                children: [
                    {
                        path: 'list/:page',
                        name: 'costList',
                        component: () => import('../views/private/cost/pages/ListPage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'create',
                        name: 'createCost',
                        component: () => import('../views/private/cost/pages/CreatePage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'edit/:id',
                        name: 'editCost',
                        component: () => import('../views/private/cost/pages/EditPage.vue'),
                        meta: {roles: userRole},
                    },
                ]
            }, {
                path: 'trip',
                component: () => import('../views/private/trip/TripPage.vue'),
                children: [
                    {
                        path: 'list/:page',
                        name: 'tripList',
                        component: () => import('../views/private/trip/pages/ListPage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'create',
                        name: 'createTrip',
                        component: () => import('../views/private/trip/pages/CreatePage.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'edit/:id',
                        name: 'editTrip',
                        component: () => import('../views/private/trip/pages/EditPage.vue'),
                        meta: {roles: userRole},
                    },
                ]
            }, {
                path: 'report',
                component: () => import('../views/private/report/ReportPage.vue'),
                children: [
                    {
                        path: 'waiting-time',
                        name: 'waitingTimeReport',
                        component: () => import('../views/private/report/reports/WaitingTimeReport.vue'),
                        meta: {roles: userRole},
                    },
                ]
            }, {
                path: 'profile',
                component: () => import('../views/private/profile/Profile.vue'),
                children: [
                    {
                        path: 'edit',
                        name: 'editProfile',
                        component: () => import('../views/private/profile/EditProfile.vue'),
                        meta: {roles: userRole},
                    }, {
                        path: 'change-password',
                        name: 'changePassword',
                        component: () => import('../views/private/profile/ChangePassword.vue'),
                        meta: {roles: userRole},
                    }
                ]
            }, {
                path: 'admin',
                component: () => import('../views/private/admin/Admin.vue'),
                children: [
                    {
                        path: '',
                        redirect: {name: 'users', params: {page: 1}},
                        meta: {roles: userRole},
                    }, {
                        path: 'users',
                        component: () => import('../views/private/admin/users/UserPage.vue'),
                        children: [
                            {
                                path: 'list/:page',
                                name: 'userList',
                                component: () => import('../views/private/admin/users/UserListingPage.vue'),
                                meta: {roles: userRole},
                            }
                        ]
                    }, {
                        path: 'translator/:page',
                        name: 'translator',
                        component: () => import('../views/private/admin/translator/Translator.vue'),
                        meta: {roles: userRole},
                    },
                ]
            }
        ]
    }, {
        path: '*',
        redirect: {
            name: 'dashboard',
            meta: {roles: userRole},
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});


const resolveAccess = (user, route, next) => {
    const response = hasRouteAccess(user, route)

    if (response === true) {
        next()
    } else {
        router.push(response)
    }
}

router.beforeEach((to, from, next) => {
    if (store.state.account.user === null) {
        store.dispatch('account/get').then((data) => {
            resolveAccess(data.loggedIn === true ? data.account : null, to, next)
        }, () => {
            resolveAccess(null, to, next)
        })
    } else {
        resolveAccess(store.state.account.user, to, next)
    }
})

export default router
