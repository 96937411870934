import api, {defaultPaginator} from '../api';

const baseUrl = 'client'

export default {
    namespaced: true,
    state: {
        clients: {
            loading: false,
            paginator: {...defaultPaginator},
            items: [],
        },
        client: {
            loading: false,
            item: null
        },
    },
    getters: {},
    actions: {
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Client was created')
        },
        update(store, data) {
            store.state.client.loading = false
            return api.editItem(store, baseUrl + '/' + data.id, data, 'Client was updated', (response) => {
                store.commit('setClient', response)
            })
        },
        getById(store, id) {
            store.state.client.loading = true

            return api.getItem(store, baseUrl + '/' + id, (response) => {
                store.commit('setClient', response)
            })
        },
        list(store, params) {
            store.state.clients.loading = true

            return api.list(store, baseUrl + '/list', params, ({data}) => {
                store.state.clients = {
                    loading: false,
                    paginator: data.paginator,
                    items: data.items,
                }
            })
        },
    },
    mutations: {
        setClient(state, response) {
            state.client.loading = false
            state.client.item = response.data.item
        }
    }
}
