export const months = (t) => {
    return {
        1: t('January'),
        2: t('February'),
        3: t('March'),
        4: t('April'),
        5: t('May'),
        6: t('Jun'),
        7: t('July'),
        8: t('August'),
        9: t('September'),
        10: t('October'),
        11: t('November'),
        12: t('December'),
    }
}

export const monthValues = (t) => {
    return [
        {
            id: 1,
            name: t('January')
        }, {
            id: 2,
            name: t('February')
        }, {
            id: 3,
            name: t('March')
        }, {
            id: 4,
            name: t('April')
        }, {
            id: 5,
            name: t('May')
        }, {
            id: 6,
            name: t('Jun')
        }, {
            id: 7,
            name: t('July')
        }, {
            id: 8,
            name: t('August')
        }, {
            id: 9,
            name: t('September')
        }, {
            id: 10,
            name: t('October')
        }, {
            id: 11,
            name: t('November')
        }, {
            id: 12,
            name: t('December')
        }
    ]
}

export const yearValues = (emptyItem = null, startAt = 2010) => {
    const out = [];

    if (emptyItem !== null) {
        out.push({
            id: null,
            name: emptyItem,
        })
    }

    for (let i = new Date().getFullYear(); i>= startAt; i--) {
        out.push({
            id: i,
            name: i
        })
    }

    return out;
}

export const companyType = (t) => {
    return [
        {
            id: 'company',
            name: t('SRO')
        }, {
            id: 'self-employed',
            name: t('Self-employed')
        }, {
            id: 'personal',
            name: t('Personal')
        }
    ]
}

export const companyColor = (t) => {
    return [
        {
            id: 'yellow',
            name: t('Yellow')
        }, {
            id: 'purple',
            name: t('Purple')
        }, {
            id: 'green',
            name: t('Green')
        }
    ]
}

export const getMonth = (t, month) => {
    return months(t)[month]
}

export const userGroups = (t, store) => {
    const userGroups = store.state.account.user === null ? [] : store.state.account.user.userGroup

    return [
        {
            id: null,
            name: t('All companies')
        },
        ...userGroups
    ]
}

export const colors = (t) => {
    return [
        {
            id: 'red',
            name: t('Red')
        }, {
            id: 'orange',
            name: t('Orange')
        }, {
            id: 'yellow',
            name: t('Yellow')
        }, {
            id: 'green',
            name: t('Green')
        }, {
            id: 'blue',
            name: t('Blue')
        }, {
            id: 'purple',
            name: t('Purple')
        }, {
            id: 'pink',
            name: t('Pink')
        }, {
            id: 'darkSilver',
            name: t('Dark silver')
        }, {
            id: 'lightSilver',
            name: t('Light silver')
        }
    ]
}