<template>
    <div class="w-100">
        <review-rating :rating="value" :editable="true" @clicked="value = $event"/>
    </div>
</template>
<script>
import {abstractField} from "vue-form-generator"
import FormMixin from "vue-titan-form/src/form/FormMixin";
import ReviewRating from "@/components/ReviewRating.vue";

export default {
    name: "Rating",
    components: {ReviewRating},
    mixins: [
        abstractField,
        FormMixin
    ],
}
</script>

<style scoped>

</style>