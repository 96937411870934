import api from './../api';


export const treeSelect = (data, out = []) => {
    data.forEach((item) => {
        let user = {
            id: item.id,
            label: item.fullName,
        }
        const children = treeSelect(item.user);
        if (children.length !== 0) { // inserted same user that's why 1
            const parentUser = {...user}
            user.id = user.id + '-parent'
            user.children = [...[parentUser], ...children];
        }
        out.push(user)
    })
    return out;
}

export const treeSelectNew = (data, out = []) => {
    data.forEach((item) => {
        let user = {
            id: item.id,
            label: item.name,
        }
        const children = treeSelectNew(item.children);
        if (children.length !== 0) { // inserted same user that's why 1
            const parentUser = {...user}
            user.id = user.id + '-parent'
            user.children = [...[parentUser], ...children];
        }
        out.push(user)
    })
    return out;
}

export default {
    namespaced: true,
    state: {
        codeBooks: {
            client: [],
        }
    },
    getters: {
        get(state) {
            return function (codeBook) {
                return state.codeBooks[codeBook] === undefined ? [] : state.codeBooks[codeBook];
            };
        }
    },
    actions: {
        load(store, codebooks) {
            return api.getItem(store, 'code-book/list/' + codebooks, (response) => {
                store.commit('setCodeBooks', response.data);
            })
        },
    },
    mutations: {
        setCodeBooks(state, data) {
            Object.keys(data).forEach(item => {
                state.codeBooks[item] = data[item];
            });
        }
    }
}
