<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "Color",
    props: {
        color: {
            type: String,
            default: '#000000'
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        styleClass() {
            return 'term-bg-' + this.color
        }
    }
})
</script>

<template>
    <div class="color" @click="$emit('select')" :class="styleClass">
        <font-awesome-icon icon="check" v-if="selected"/>
    </div>
</template>

<style lang="scss" scoped>
.color {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50px;
    border: 1px solid #fff;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4)

}
</style>