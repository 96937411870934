<template>
    <div class="w-100">
        <input type="date" v-model="valueItem" class="form-control">
    </div>
</template>
<script>
import {abstractField} from "vue-form-generator"
import FormMixin from "vue-titan-form/src/form/FormMixin";

export default {
    name: "NativeDate",
    mixins: [
        abstractField,
        FormMixin
    ],
    watch: {
        valueItem() {
            this.value = this.valueItem === '' ? null : this.valueItem
        }
    },
    mounted() {
        this.valueItem = this.value === null || this.value === undefined ? null : this.value.slice(0, 10);
    },
    data() {
        return {
            valueItem: null,
        }
    }
}
</script>

<style scoped>

</style>