import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import account from './modules/account';
import codeBook from './modules/codeBook';
import user from './modules/user';
import acl from './modules/acl';
import report from "@/store/modules/report";
import invoices from "@/store/modules/invoices";
import trips from "@/store/modules/trips";
import costs from "@/store/modules/costs";
import groups from "@/store/modules/groups";
import clients from "@/store/modules/clients";

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        account,
        codeBook,
        user,
        acl,
        report,
        invoices,
        trips,
        costs,
        groups,
        clients,
    }
});

export const availableMonths = () => {
    const currentYear = (new Date()).getFullYear()
    const currentMonth = (new Date()).getMonth() + 1;
    const out = [];
    for (let year = 2021; year <= currentYear; year++) {
        for (let month = 1; month <= 12; month++) {
            if ((year === 2021 && month < 4) || (year === currentYear && month > currentMonth)) {
                continue;
            }
            const name = month + '/' + year;
            out.push({
                id: name,
                month: month,
                year: year,
            })
        }
    }
    return out;
}

export const getBase64 = (store, file) => {
    return new Promise((resolve, reject) => {
        if (['image/jpeg', 'image/png'].includes(file.type) === false) {
            store.dispatch('alert/error', 'Unsupported image format')
            reject();
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        }
    });
}

