import api, {crud} from './../api';

const baseUrl = 'user';

export default {
    namespaced: true,
    state: {
    },
    actions: {
        ...crud(api, baseUrl),
        block(store, id) {
            return api.editItem(store, baseUrl + '/block/' + id, {}, 'User was blocked')
        }
    },
    mutations: {

    },
}
