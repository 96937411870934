import api, {apiLink, defaultPaginator, query} from '../api';

const baseUrl = 'trip'

export default {
    namespaced: true,
    state: {
        listFilter: {
            group: null,
            search: '',
            year: null,
        },
        trips: {
            loading: false,
            paginator: {...defaultPaginator},
            items: [],
        },
        trip: {
            loading: false,
            item: null
        },
    },
    getters: {},
    actions: {
        getTripReport(store, data) {
            return api.getItem(store, baseUrl + '/last-months' + query(data))
        },
        create(store, data) {
            return api.postItem(store, baseUrl, data, 'Trip was created')
        },
        generateNumbers(store, data) {
            return api.postItem(
                store,
                baseUrl + '/generate-number/' + data.groupId + '/' + data.year,
                {},
                'Numbers were generated'
            )
        },
        copy(store, data) {
            return api.editItem(store, baseUrl + '/copy/' + data.id, data, 'Trip was copied')
        },
        update(store, data) {
            store.state.trip.loading = false

            return api.editItem(store, baseUrl + '/' + data.id, data, 'Trip was updated', (response) => {
                store.commit('setTrip', response)
            })
        },
        removeById(store, id) {
            return api.deleteItem(store, baseUrl + '/' + id, 'Trip was deleted')
        },
        getById(store, id) {
            store.state.trip.loading = true

            return api.getItem(store, baseUrl + '/' + id, (response) => {
                store.commit('setTrip', response)
            })
        },
        list(store, params) {
            store.state.trips.loading = true

            return api.list(store, baseUrl + '/list', params, ({data}) => {
                store.state.trips = {
                    loading: false,
                    paginator: data.paginator,
                    items: data.items,
                }
            })
        },
        items(store, params) {
            return api.getItem(store, baseUrl + '/items/1/20' + query(params))
        },
    },
    mutations: {
        setTrip(state, response) {
            state.trip.loading = false
            state.trip.item = response.data.item
        }
    }
}

export const tripLink = (id, renderSignature = true) => {
    return apiLink() + baseUrl + '/download/' + id + (renderSignature === true ? '' : '?renderSignature=0')
}
export const tripFileLink = (id, fileName) => {
    return apiLink() + '/file/trip/' + id + '/' + fileName
}
export const downloadAllLink = (groupId, year) => {
    return apiLink() + baseUrl + '/download-all/' + groupId + '/' + year
}