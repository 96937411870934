import api, {csrfKey} from '../api';

const hasPermissions = (state, slug, type) => {
    if (state.user === null || !state.user.role || state.user.role.roleAcl === undefined) {
        return false;
    }
    const found = state.user.role.roleAcl.find((item) => {
        return item.acl.slug === slug;
    });
    return found !== undefined && found.value.includes(type);
}

export default {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        account(state, getters) {
            return state.user;
        },
        hasPermission(state) {
            return (slug, type) => hasPermissions(state, slug, type)
        },
        notificationAccess(state) {
            return hasPermissions(state, 'NotificationTitan', 'CRUD')
        },
        adminAccess(state) {
            return hasPermissions(state, 'AdminTitan', 'CRUD')
        },
        reportAccess(state) {
            return hasPermissions(state, 'ReportsTitan', 'CRUD')
        },
        orderAccess(state) {
            return hasPermissions(state, 'OrderTitan', 'CRUD')
        },
        managePatientAccess(state) {
            return hasPermissions(state, 'PatientTitan', 'C') || hasPermissions(state, 'PatientTitan', 'U')
        },
        deletePatientAccess(state) {
            return hasPermissions(state, 'PatientTitan', 'D')
        },
        developerAccess(state) {
            return state.user && state.user.developer
        },
    },
    actions: {
        login(store, params) {
            return new Promise((resolve, reject) => {
                api.post('account/login', params).then(response => {
                    localStorage.setItem(csrfKey, response.data.csrf);
                    resolve();
                }).catch(error => {
                    reject(error.response);
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                });
            });
        },
        get(store) {
            return new Promise((resolve, reject) => {
                api.get('account').then(response => {
                    store.commit('setProfile', response.data);
                    resolve(response.data);
                }).catch(error => {
                    reject(error.response);
                });
            });
        },
        stats(store) {
            return new Promise((resolve, reject) => {
                api.get('account/stats').then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error.response);
                });
            });
        },
        logout(store) {
            return new Promise((resolve, reject) => {
                api.post('account/logout').then(() => {
                    localStorage.removeItem(csrfKey);
                    store.commit('setProfile', {});
                    resolve();
                }).catch(error => {
                    reject(error.response);
                });
            });
        },
        changePassword(store, params) {
            return new Promise((resolve, reject) => {
                api.put('account/change-password', params).then(response => {
                    store.dispatch('alert/success', 'Password was changed', {root: true});
                    resolve(response);
                }).catch(error => {
                    reject(error.response);
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                });
            });
        },
        updateProfile(store, params) {
            return new Promise((resolve, reject) => {
                api.put('account/update-profile', params).then(response => {
                    store.dispatch('alert/success', 'Profile was updated', {root: true});
                    store.dispatch('get');
                    resolve(response);
                }).catch(error => {
                    reject(error.response);
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                });
            });
        },
        lostPassword(store, params) {
            return new Promise((resolve, reject) => {
                api.post('account/lost-password', params).then(response => {
                    store.dispatch('alert/success', 'Please check your e-mail address', {root: true});
                    resolve(response);
                }).catch(error => {
                    reject(error.response);
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                });
            });
        },
        validateToken(store, params) {
            return api.get('account/get-lost-password/' + params.id + '/' + params.hash);
        },
        resetPassword(store, params) {
            return new Promise((resolve, reject) => {
                api.put('account/reset-password/' + params.id + '/' + params.hash, params).then((response) => {
                    store.dispatch('alert/success', 'Password changed, please login', {root: true});
                    resolve(response);
                }).catch(error => {
                    reject(error.response);
                    store.dispatch('alert/error', error.response.data.status, {root: true});
                });
            });
        },
        uploadSignature(store, data) {
            return new Promise((resolve, reject) => {
                api.editItem(store, 'account/signature', data, 'Signature was updated!', () => {
                    resolve()
                }).catch(() => {
                    reject()
                })
            })
        }
    },
    mutations: {
        setProfile(state, data) {
            state.user = data.account || null;
        },
    }
}
