<template>
    <div class="w-100">
        <input type="time" v-model="value" class="form-control">
    </div>
</template>
<script>
import {abstractField} from "vue-form-generator"
import FormMixin from "vue-titan-form/src/form/FormMixin";

export default {
    name: "NativeTime",
    mixins: [
        abstractField,
        FormMixin
    ],
}
</script>

<style scoped>

</style>