<template>
    <div class="review-rating" :class="'rating-' + rating">
        <div class="rating" :class="{'editable': editable}" v-for="rating in 5" @click="$emit('clicked', rating)">
            <font-awesome-icon icon="star"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewRating",
    props: {
        rating: {
            type: Number | null,
            required: true,
        },
        editable: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.review-rating {
    $yellow: #ffb333;

    display: flex;
    flex-direction: row;
    color: #ccc;
    font-size: 14px;

    .rating.editable {
        font-size: 18px;
        cursor: pointer;
    }

    &.rating-1 {
        .rating:nth-child(-n+1) {
            color: $yellow;
        }
    }

    &.rating-2 {
        .rating:nth-child(-n+2) {
            color: $yellow;
        }
    }

    &.rating-3 {
        .rating:nth-child(-n+3) {
            color: $yellow;
        }
    }

    &.rating-4 {
        .rating:nth-child(-n+4) {
            color: $yellow;
        }
    }

    &.rating-5 {
        .rating {
            color: $yellow;
        }
    }
}
</style>