<template>
    <div class="contact-quick-search">
        <div class="quick-search-loading-wrapper" v-if="loading">
            <titan-loading size="xs"/>
        </div>
        <input type="text"
               v-model="value.name"
               class="form-control"
               :placeholder="placeholder"
               :disabled="disabled"
               @focus="setBoxView(true)"
               @blur="setBoxView(false)"
               @keyup.esc="removeSearch(true)"
               @keyup.enter="addItemByIndex(activeIndex)"
               @keyup.down="selectNext($event)"
               @keyup.up="selectPrev($event)"
               @keyup="searchItem($event)"
               @keydown.enter.prevent
               @keydown.esc.prevent
               @keydown.down.prevent
               @keydown.up.prevent
               autocomplete="off"
        >
        <div class="search-box" v-if="showBox === true">
            <div class="search-box-info"
                 v-if="searchResults.length === 0 && value.name.length >= minStringLength && loading === false"
            >
                {{ i18n('No results found') }}
            </div>
            <div class="search-box-info" v-if="value.name.length < minStringLength">
                {{ i18n('Type at least {count} chars').replace('{count}', minStringLength) }}
            </div>
            <ul v-if="searchResults.length !== 0">
                <li v-for="(item, index) in searchResults"
                    :key="item.id"
                    @mouseover="activeIndex = index"
                    @mouseleave="activeIndex = null"
                    @click="addItemByIndex(index)"
                    :class="{'active':index === activeIndex}"
                >
                    <div class="item-name" data-cy="autocomplete-name">
                        {{ item.name }}
                        <small v-if="item.age !== null"> - {{item.age}} ({{item.birthYear}})</small>
                    </div>
                    <div class="item-details">
                        <span v-if="item.email !== null">{{ item.email }}</span>
                        <span v-if="item.phone !== null">{{ item.phone }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import {abstractField} from "vue-form-generator"
import {TitanLoading} from "vue-titan-elements"
import FormMixin from "vue-titan-form/src/form/FormMixin";

export const contactValidator = (value) => {
    return value.name.length > 2 ? [] : ['Patient name has to have at least 2 chars']
}

export default {
    name: 'PatientAutocomplete',
    components: {
        TitanLoading,
    },
    mixins: [abstractField, FormMixin],
    computed: {
        placeholder() {
            return this.schema.placeholder === undefined ? '' : this.schema.placeholder;
        },
    },
    methods: {
        setBoxView(type) {
            setTimeout(() => {
                this.showBox = type;
                if (type === true && this.value.name.length > this.minStringLength) {
                    this.searchItem()
                }
            }, 200);
        },
        selectNext(event) {
            event.preventDefault();
            this.activeIndex = this.activeIndex === null || this.activeIndex >= (this.searchResults.length - 1)
                ? 0
                : this.activeIndex + 1;
        },
        selectPrev(event) {
            event.preventDefault();
            const lastIndex = this.searchResults.length - 1;
            this.activeIndex = this.activeIndex === null || this.activeIndex === 0
                ? lastIndex
                : this.activeIndex - 1;
        },
        searchItem(event) {
            if (event === undefined
                || (event.key !== 'ArrowDown' && event.key !== 'ArrowUp' && event.key !== 'Enter')
            ) {
                if (event !== undefined) {
                    this.value.id = null
                    this.triggerChanged(null)
                }
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.callApi();
                }, 300);
            }
        },
        callApi() {
            if (this.value.name.trim().length >= this.minStringLength) {
                this.loading = true;
                this.$store.dispatch('patient/search', this.value.name).then((data) => {
                    this.setData(data);
                }).catch(() => {
                    this.setData([]);
                });
            } else {
                this.setData([]);
            }
        },
        setData(data) {
            if (this.searchResults.length - 1 < this.activeIndex) {
                this.activeIndex = null;
            }
            this.searchResults = data;
            this.loading = false;
        },
        addItemByIndex(index) {
            const item = this.searchResults[index];
            if (item !== undefined) {
                this.value = {
                    id: item.id,
                    name: item.name,
                }
                this.triggerChanged(item)
                this.removeSearch();
            }
        },
        triggerChanged(values) {
            if (this.schema.changed !== undefined) {
                this.schema.changed(values)
            }
        },
        removeSearch(showBox = false) {
            this.setBoxView(showBox)
            this.activeIndex = null;
            this.loading = false;
            this.searchResults = [];
        },
    },
    data() {
        return {
            minStringLength: 2,
            showBox: false,
            activeIndex: null,
            loading: false,
            searchResults: [],
            timeout: null,
        }
    }
};
</script>
<style lang="scss" scoped>
.contact-quick-search {
    position: relative;
    width: 100%;

    ::v-deep {

        .quick-search-loading-wrapper {
            position: absolute;
            top: 2px;
            right: 0;
        }

        .form-control {
            outline: none;
            width: 100%;
        }

        .data-loader {
            position: absolute;
            top: 10px;
            right: 25px;
        }

        .search-box {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background: #fff;
            border: solid #eee;
            border-width: 0 1px 1px 1px;
            border-radius: 5px 5px 5px 5px;

            -webkit-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.15);
            box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.15);
            z-index: 10;
            padding: 0;
            margin: 0;

            .search-box-info {
                padding: 13px;
                font-size: 0.9rem;
            }

            > ul {
                list-style: none;
                padding: 0;
                margin: 0;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    padding: 8px 13px;
                    display: block;
                    color: #000;
                    text-decoration: none;
                    cursor: pointer;
                    font-size: .95rem;
                    border-bottom: 1px solid #eee;

                    .item-name {
                        line-height: 110%;
                    }

                    .item-details {
                        font-size: 13px;
                        opacity: 0.7;
                        line-height: 110%;

                        > span {
                            &:not(:last-child):after {
                                content: ' - '
                            }
                        }
                    }

                    &.active {
                        background: #009DFA;
                        color: #fff;
                        border-color: #009DFA;
                    }

                    &:first-child {
                        border-radius: 5px 5px 0 0;
                    }

                    &:last-child {
                        border-radius: 0 0 5px 5px;
                    }
                }
            }
        }
    }
}
</style>